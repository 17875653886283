import React from 'react'
import { compose } from 'recompose'
import { withLayout } from '../../../components/layout'

const contactList = [
	{
		address: 'м. Львів, вул. Зелена 253',
		addressLink: 'https://maps.app.goo.gl/3hgJE7yFfST7oFQX6',
		phone: '+380969007676',
		workingHours: 'Пн-Сб 10:00-19:00',
		locationUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2575.024717751578!2d24.06185597718451!3d49.80440297147608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473add0b48541f1f%3A0x33164d9238390d59!2zR2xvYmFsIEF1dG8gTG9naXN0aWMg0JDQstGC0L4g0Lcg0KHQqNCQ!5e0!3m2!1sen!2sua!4v1741861024661!5m2!1sen!2sua',
	},
	{
		address: 'Львів, вул. Газова 36/4',
		addressLink: 'https://goo.gl/maps/x2VfoQo833tACWbcA',
		phone: '+380672731130',
		workingHours: 'Пн-Сб 10:00-19:00',
		locationUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2572.688434431428!2d24.0209668!3d49.8483115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473add0b48541f1f%3A0x33164d9238390d59!2z0JDQstGC0L4g0Lcg0KHQqNCQINGC0LAg0JrQsNC90LDQtNC4IEdsb2JhbCBBdXRvIExvZ2lzdGlj!5e0!3m2!1sen!2sua!4v1687810535167!5m2!1sen!2sua',
	},
	{
		address: 'м. Київ, Хрещатик 7/11',
		addressLink: 'https://goo.gl/maps/oSkjF2UUKo2sq6bZ9',
		phone: '+380688368686',
		workingHours: 'Пн-Сб 10:00-19:00',
		locationUrl: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2540.4855078796186!2d30.5234401!3d50.4506832!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce51bcbdf833%3A0x6eeea398c0516fd0!2sKhreschatyk%20St%2C%207%2F11%2C%20Kyiv%2C%2002000!5e0!3m2!1sen!2sua!4v1687811039367!5m2!1sen!2sua',
	},
	// {
	// 	address: 'м. Луцьк, вул. Лесі Українки 16',
	// 	addressLink: 'https://goo.gl/maps/PNYpj9uhkEuuB9QV6',
	// 	phone: '+380971264991',
	// 	workingHours: 'Пн-Сб 10:00-19:00',
	// 	locationUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d204.64245487176922!2d25.31925507134059!3d50.74193172340762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4725974adaa2c39f%3A0xd2d466d95a0de2c!2z0KbQtdC90YLRgCDQkdC40YDQttC10LLRi9GFINCi0LXRhdC90L7Qu9C-0LPQuNC5!5e0!3m2!1sen!2sua!4v1687812063653!5m2!1sen!2sua',
	// },
	{
		address: 'м. Івано-Франківськ, вул. Галицька 41а',
		addressLink: 'https://maps.app.goo.gl/QnsVNwZxmuxo9SKdA',
		phone: '+380660255730',
		workingHours: 'Пн-Сб 10:00-19:00',
		locationUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2621.5341227902118!2d24.707040776894598!3d48.92426769606765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4730c113198ba0ed%3A0x7e5feeb91cb57916!2sHalyts\'ka%20St%2C%2041%2C%20Ivano-Frankivs\'k%2C%20Ivano-Frankivs\'ka%20oblast%2C%2076000!5e0!3m2!1sen!2sua!4v1694990392486!5m2!1sen!2sua',
	},
	{
		address: 'КД, ЗО Львів, 10 км, Сокільники, Львівська обл., 81130 (ГЛОБАЛ АВТО ПАРК)',
		addressLink: 'https://goo.gl/maps/BbnULHa7jTNpTfL37',
		phone: '+380962026727',
		workingHours: 'Пн-Сб 10:00-19:00',
		locationUrl: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2575.921879317632!2d23.946158000000004!3d49.78753400000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDnCsDQ3JzE1LjEiTiAyM8KwNTYnNDYuMiJF!5e0!3m2!1sen!2sua!4v1687811838508!5m2!1sen!2sua',
	},
	{
		address: 'Луцьк, вул. Словацького 7',
		addressLink: 'https://goo.gl/maps/x2VfoQo833tACWbcA',
		phone: '+380969947321',
		workingHours: 'Пн-Сб 10:00-19:00',
		locationUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2572.688434431428!2d24.0209668!3d49.8483115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473add0b48541f1f%3A0x33164d9238390d59!2z0JDQstGC0L4g0Lcg0KHQqNCQIEdsb2JhbCBBdXRvIExvZ2lzdGlj!5e0!3m2!1sen!2sua!4v1726151515446!5m2!1sen!2sua',
	},
	// {
	// 	address: 'Одеса, вул. Львівська 48',
	// 	addressLink: 'https://maps.app.goo.gl/e9UyPCVJkNmpqrLn6',
	// 	phone: '+380962304578',
	// 	workingHours: 'Пн-Сб 10:00-19:00',
	// 	locationUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1265.900215459633!2d30.742033362538425!3d46.401208525043536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c63498f0fb8c1d%3A0x5a2d8f7e81ab1062!2sL\'vivs\'ka%20St%2C%2048%2C%20Odesa%2C%20Odes\'ka%20oblast%2C%2065000!5e0!3m2!1sen!2sua!4v1737048248712!5m2!1sen!2sua',
	// },
	{
		address: 'Вінниця,вул. Київська 16а',
		addressLink: 'https://maps.app.goo.gl/BJbgweEpku6pVgMVA',
		phone: '+380680241094',
		workingHours: 'Пн-Сб 10:00-19:00',
		locationUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1302.4835834973594!2d28.47957502818036!3d49.23911169770478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472d5b333086adab%3A0xde6f441bc5ff5f44!2zR2xvYmFsIEF1dG8gTG9naXN0aWMg0JDQstGC0L4g0Lcg0KHQqNCQ!5e0!3m2!1sen!2sua!4v1741862878913!5m2!1sen!2sua',
	},
]
const ContactsComponent = () =>
	<>
		<div id="page_caption" className="withtopbar no_picture">
			<div className="page_title_wrapper">
				<div className="page_title_inner">
					<h1 className="withtopbar">Контакти</h1>
				</div>

			</div>
		</div>
		<div id="page_content_wrapper" className="hasbg withtopbar">
			<div className="contact_us_page_content">
				<div className="list-container">
					{contactList.map((contact, ci) => <div className="list-item" key={ci}>
							<div className="details">
								<div className="address">
									<span className="ti-location-pin"/>
									<a href={contact.addressLink} target="_blank"
									   rel="noopener noreferrer">
										{contact.address}
									</a>
								</div>
								<div className="phone">
									<span className="ti-mobile"/>
									<a href={`tel:${contact.phone}`}
									   className="no_hover">{contact.phone}</a>
								</div>
								<div className="working-hours">
									<span className="ti-alarm-clock"/>
									{contact.workingHours}
								</div>
							</div>
							<div className="location-image">
								<iframe
									key={contact.address}
									title={contact.address}
									src={decodeURI(contact.locationUrl)}
									width="100%" height="300" style={{ border: 0 }}
									allowFullScreen="" loading="lazy"
									referrerPolicy="no-referrer-when-downgrade">
								</iframe>
							</div>
						</div>,
					)}
				</div>
			</div>
		</div>

	</>

export const Contacts = compose(
	withLayout,
)(ContactsComponent)

